<template>
  <div class="menu-edit-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          @click="createQuestionGroup"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-edit-page__body mb-6 mt-6">
      <b-overlay :show="loading">
        <FormQuestion ref="form" @submit="submit" />
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  CREATE_QUESTION_GROUP,
  RESET_STATE_SURVEY,
} from '@/core/services/store/survey.module';
const { mapActions, mapMutations } = createNamespacedHelpers('survey');
export default {
  name: 'SurveyQuizeCreate',
  components: {
    FormQuestion: () => import('./components/Form.vue'),
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeDestroy() {
    this.RESET_STATE_SURVEY();
  },
  methods: {
    ...mapActions({ CREATE_QUESTION_GROUP }),
    ...mapMutations({ RESET_STATE_SURVEY }),
    returnPage() {
      this.$router.go(-1);
    },
    createQuestionGroup() {
      this.$refs.form.handleValidate();
    },
    async submit(params) {
      this.loading = true;
      const { error } = await this.CREATE_QUESTION_GROUP(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Thêm mới thành công',
        });
        this.$router.push({
          name: 'survey_quize_list',
        });
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang=""></style>
